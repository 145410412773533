@import "fonts";
@import "poole";
@import "syntax";
@import "lanyon";

.skip-link {
  /* Remove item from normal flow */
  position: absolute;
  top: 8px;
  right: 8px;
}

.skip-link:not(:focus) {
  /* Workaround for falsely pronounced, smushed text */
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  /* It seems like at the moment nobody is quite sure why margin: -1px is there. On top of that it seems to cause issues (see: https://github.com/h5bp/html5-boilerplate/issues/1985). */
  margin: -1px;
}

.support {
  display: inline-block;
  vertical-align: sub;
  height: 22px;
}

.post a {
  &:hover {
    text-decoration: none;
    border-bottom: 0.1rem solid #268bd2;
  }
  &.anchorjs-link:hover {
    border-bottom: none;
  }
}

.post-read-time {
  display: inline-block;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: #9a9a9a;
}

/* Icons */
.icon {
  max-width: 2rem;
  height: 1rem;
  fill: currentColor;
  display: inline-block;
}

/* Reading time icon */
.icon-clock {
  margin: -0.15em 0;
}

/* Social icons */
.social {
  li {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  a:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}

.copy {
  padding-top: 2rem;
  text-align: center;
  font-size: 75%;
  font-style: italic;
  color: #999;
}

.emoji {
  display: inline;
}

.video-holder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.video-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Contact Form */

.hidden {
  display: none;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  border: 1px solid rgba(0,0,0,.15)
}

.form-control,
.btn {
  padding: .5rem .75rem;
  font-size: 1rem;
  border-radius:0.25rem;
}

.btn {
  color: #fff;
  background-color: #268bd2;
  border: 1px solid transparent;
  border-color: #268bd2;

  &:hover {
    background-color: darken(#268bd2, 5%);
  }
}

.mask {
  -webkit-mask-image: linear-gradient(#000 calc(100% - 9rem), transparent);
          mask-image: linear-gradient(#000 calc(100% - 9rem), transparent);
}
